//**********************************// sidebar-box start //**********************************//
.side-bar_bg {
    transition: all 0.2s linear;

  
    .sidebar_box {
      contain: strict;
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0;
      text-align: left;
      visibility: visible;
      z-index: 9;
      width: 256px;
      margin: 0;
      padding: 0 0 65px;
      height: 100vh;
      float: left;
      background: #fcfcfc;
      transition: all 0.4s linear;
      overflow: hidden auto;
  
      .side-bar-close-icon {
        position: absolute;
        top: -1px;
        right: 9px;
        font-size: 24px;
        z-index: 99;
        cursor: pointer;
        display: none;
       color: black;

      }
  
      .logo-box {
        width: 186px;
    height: auto;
    margin: 9px auto 16px;
  
        img {
          width: 100%;
          height: auto;
          display: block;
          margin: 0 auto 0;
        }
      }
  
      .manu_user_menu {
        margin: 0;
        padding: 0 0 14px;
  
        .top_menu_bar {
          margin: 0;
          padding: 0 ;
          display: flex;
          flex-direction: column;
          gap: 7px;
          width: 100%;
  

          li {
            width: 100%;
            display: flex;
  
            .sidbar_menu_font {
              display: flex;
              align-items: baseline;
              justify-content: flex-start;
              transition: all 0.2s linear;
              color: #57546c;
              border-radius: 0;
              height: 2.5rem;
              padding:8px 15px 9px;
              margin: 1px 0;
              white-space: nowrap;
              width: 100%;
              column-gap: 0.5rem;
              border-left:3.8px solid #dde4ee ;
              
              background-color: transparent;
            
              .icon_hos{
                font-size: 20px;
                line-height: 0;
              }

              .text-box {
                white-space: nowrap;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 20px;
                text-transform: capitalize;
                text-decoration: none;
              }
              &:hover {
                background-color: #fff;
              }
              &.active {
                color: #4719dd;
                border-left:3.8px solid #4719dd ;
              background-color: #f6f3ff;
      
            }
          }
        }
      }
 
    }
  }
}

@media screen and (max-width: 992px){

  .side-bar_bg .sidebar_box .side-bar-close-icon{
    display: block;
  }
.side-bar_bg.sidebar-open .sidebar_box {
    width: 220px;
    height: 100vh;
    transition: all .3s linear;
    padding: 3px 0 66px;
}
.side-bar_bg .sidebar_box {
  width: 0;
}
.side-bar_bg.sidebar-open:before {
  content: "";
  position: absolute;
  top: 0;
  z-index: 8;
  width: 100%;
  height: 100vh;
  display: block;
  transition: all .5s linear;
  background: #3838385d;
}
.side-bar_bg .sidebar_box .logo-box {
  width: 186px;
  height: auto;
  margin: 29px auto 16px;
}
}
  // **********************************// sidbar-box end //**********************************//
  