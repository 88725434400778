// -----------table--------

.recentOrders {
  position: relative;
  background: transparent;
  padding: 10px 10px;
  border-radius: 10px;
  background-color: #fff;

  .report-card-box {
    width: 100%;
    border-collapse: collapse;
    margin-top: 5px;

    tr th {
      font-weight: 600;
      white-space: nowrap;
      font-size: 13px;
      padding: 9px 3px;
      color: #5b586c;
      text-transform: uppercase;
      border-bottom: 0.8px solid #b1b1b1;
      background: #f6f3ff;
    }

    tbody {
      tr td {
        font-weight: 400;
        white-space: nowrap;
        font-size: 12px;
        padding: 8px 4px;
        color: #000;
        text-transform: uppercase;
        position: relative;

        b {
          font-weight: 600;
        }
      }

      tr:nth-child(even) {
        background-color: #ffff;
      }

      tr:nth-of-type(odd) {
        background-color: #e6e6e6;
      }
    }
  }
}

.dataTables_length select {
  border-radius: 5px !important;
  background: #fff;
  color: #0f0f0f;
  border: 1.5px solid hsl(0deg 0% 74.9% / 45%) !important;
  height: 2.5rem;

  margin: 0 0 5px;
}
.dataTables_filter input {
  border-radius: 5px !important;
  background: #fff;
  color: #0f0f0f;
  border: 1.5px solid hsl(0deg 0% 74.9% / 45%) !important;
  height: 2.5rem;
  margin: 0 0 5px;
}



.dataTables_wrapper .dataTables_paginate .paginate_button.disabled {
  border-color: #3411b3 !important;
  color: #3411b3!important;
  font-size: 23px;
  padding: 7px 8px 0px;
  border-radius: 5px;
  line-height: 29px;
  
&:hover {
  cursor: default;
  color: #fff !important;
  border: 1px solid transparent;
  background: #3411b3 !important;
  box-shadow: none;
}
}