.page_error_container{
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.page_error_box {
  width: 50%;
  height: 505px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}
.not_found_text {
  font-family: "Lilita One", cursive;
  font-weight: 800;
}
.back_to_home {
  font-size: 22px;
  font-weight: 600;
  font-family: "Lilita One", cursive;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(0, 116, 116);
}
.back_to_home:hover > .page_error_arrow {
  margin-left: -10px;
  transition: all ease 0.5s;
}
.page_error_arrow {
  font-size: 38px !important;
}
.text_404 {
  text-align: center;
  font-size: 6rem;
  font-weight: 800;
  margin: 0;
  padding: 0;
  color: #2b71dd;
}
.not_found_pera {
  font-size: 16px;
  text-align: center;
}