@mixin animshan_but {
  position: relative;
  overflow: hidden;

  &::after {
    background: #ffffff;
    content: "";
    position: absolute;
    height: 155px;
    left: -75px;
    opacity: 0.2;
    top: -50px;
    transform: rotate(35deg);
    transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    width: 50px;
    z-index: -10;
  }

  &:hover {
    color: var(--font-white-color);
    transform: translateY(-2px);

    &::after {
      left: 180%;
      transition: all 850ms cubic-bezier(0.19, 1, 0.22, 1);
    }
  }
}

@mixin button_box {
  font-size: 1rem;
  font-family: var(--font-name-a);
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 5px;
  background: var(--main_bg_color);
  color: #fff;
  cursor: pointer;
  transition: all 0.4s ease-out 0s;
}

// ----------------login-------------------
.account-from {
  width: 100%;
  height: 100vh;
  padding: 0;
  margin: 0 auto;
  overflow: hidden auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: #f3f1f9;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 1;
    opacity: 0.4;
    display: block;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url(../assets/images/bag.jpg);
  }



  .account__wrapper {
    background-color: #fff;
    box-shadow:
      0 -16px 48px rgba(6, 35, 63, 0.03),
      0 16px 48px rgba(6, 35, 63, 0.03);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 23.9rem;
    overflow: hidden;
    border-radius: 20px;
    padding: 22px 25px;
    max-inline-size: 800px;
    margin-inline: auto;
    position: relative;
    z-index: 9;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 38%;
      z-index: -1;
      opacity: 0.9;
      display: block;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-image: url(../assets/images/bg.png);
    }

    .logo_login_box {
      margin-block-end: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      img {
        width: 226px;
        object-fit: contain;
      }
    }
    .account__header {
      margin-block-end: 36px;
      width: 100%;

      h2 {
        font-size: 3rem;
        line-height: 56px;
        font-weight: 600;
        color: var(--font-black-color);

        span {
          color: var(--main_font_color);
        }
      }

      p {
        margin-block-end: 40px;
        color: #6f7c85;
        font-size: 17px;
        line-height: 23px;
      }
    }

    .account__social {
      display: flex;
      gap: 15px;
      width: 100%;
      align-items: center;

      .social__item .social__link {
        height: 40px;
        width: 40px;
        transition: all 0.3s ease-out;
        background-color: transparent;
        border: 1px solid #eceff2;
        border-radius: 99px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .account__divider {
      margin-block: 30px;
      position: relative;

      span {
        color: #6f7c85;
        font-size: 17px;
        line-height: 6px;
        display: grid;
        gap: 12px;
        grid-auto-flow: column;
        grid-template-columns: 1fr auto 1fr;

        &:before {
          background-color: #c3baba6b;
          content: "";
          display: block;
          height: 1px;
          width: auto;
        }

        &:after {
          background-color: #c3baba6b;
          content: "";
          display: block;
          height: 1px;
          width: auto;
        }
      }
    }

    .account__form {
      .form-inpu_box {
        margin-block-end: 18px;
        width: 100%;
        position: relative;

        .text-danger {
          position: absolute;
          bottom: -24px;
          right: 0;
        }

        .form-label {
          font-size: 1.125rem;
          font-weight: 600;
          color: #0c263a;
          margin-block-end: 5px;
        }

        &.mobile_box .form-control {
          padding: 13px 5px 16px 47px;
        }
        .form-control {
          width: 100%;
          height: 100%;
          padding: 12px 15px;
          background-color: #fff;
          border: 1px solid #dee2e6;
          border-radius: 8px;
          color: #0c263a;
          appearance: none;
        }

        &.form-pass {
          margin-block-end: 8px;

          .form-pass__toggle {
            position: absolute;
            background: none;
            border: none;
            color: #0c263a;
            font-weight: 600;
            right: 0.75em;
            top: 48px;
            z-index: 9;
            cursor: pointer;
          }
        }

        .inputfield {
          width: 100%;
          display: flex;
          gap: 11px;
          justify-content: space-around;
        }
      }
    }

    .account__check {
      margin-block-start: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .account__check-remember {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        gap: 5px;

        .form-check-input {
          width: 1.2em;
          height: 1.2em;
          margin-inline-end: 3px;
          border: 1px solid #dee2e6;
          border-radius: 0.25em;
          color: #0c263a;
          appearance: none;
          cursor: pointer;
        }
        .remember-lable {
          cursor: pointer;
        }

        .form-check-label {
          font-size: 1rem;
          line-height: 15px;
          color: #0c263a;
          font-weight: 400;
          display: inline-block;
        }
      }

      .account__check-forgot {
        a {
          font-size: 1rem;
          color: #0b3c61;
          font-weight: 600;
          text-transform: capitalize;

          transition: all 0.3s ease-out;
        }
      }
    }
    .account__form{
      width: 100%;
    }

    .trk-btn-box {
      -webkit-appearance: button;
      font-size: 1rem;
      font-family: var(--font-name-a);
      font-style: normal;
      border: none;
      font-weight: 500;
      width: 100%;
      text-transform: uppercase;
      border-radius: 8px;
      background: var(--main_bg_color);
      color: #fff;
      cursor: pointer;
      padding: 12px 15px;
      margin-block-start: 10px;

      &:hover {
        background: var(--main_bg_hover-color);
      }
    }

    .account__switch {
      margin-block-start: 30px;
      text-align: center;

      p {
        color: #6f7c85;
        font-size: 17px;

        a {
          font-size: 1rem;
          color: #0b3c61;
          font-weight: 600;
          text-transform: capitalize;
          transition: all 0.3s ease-out;
        }
      }
    }
  }
}

.model_card_main {
  .modal-content {
    background-color: #f4f6ff;
  }

  .modal-header {
    background-color: #dee3ff;
    padding: 13px 14px;
    color: #0c263a;
    border: none;
  }
  .paswword_box {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    .form-inpu_box {
      margin-block-end: 18px;
      width: 100%;
      position: relative;
      .form-label {
        font-size: 15px;
        font-weight: 600;
        color: #0c263a;
        margin-block-end: 0px;
      }
      input {
        width: 100%;
        height: 100%;
        padding: 9px 15px;
        background-color: #fff;
        border: 1px solid #dee2e6;
        border-radius: 8px;
        color: #0c263a;
        appearance: none;
      }
      .form-pass__toggle {
        position: absolute;
        background: none;
        border: none;
        color: #0c263a;
        font-weight: 600;
        right: 0.75em;
        top: 38px;
        z-index: 9;
        cursor: pointer;
      }
    }
    .new_pass_but {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      gap: 5px;
      .trk-btn-box {
        @include button_box;
        font-size: 14px;
        text-transform: capitalize;
        font-weight: 500;
        padding: 6px 12px;
        overflow: hidden;
        border: none;
        &.btn-danger {
          background: #da221c;
        }
        &.btn-secondary {
          background: #6c757d;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {

  .account-from .account__wrapper .account__wrapper.account__header h2 {
    font-size: 2rem;
    line-height: 45px;
  }
  .account-from .account__wrapper .account__wrapper.account__header p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
    max-height: 1.2em;
    width: 58%;
  }
}

@media screen and (max-width: 592px) {
  .account-from .account__wrapper {
    width: 18.9rem;
  }

.account-from .account__wrapper .logo_login_box{
  margin: 8px 0 2px;
  img {
    width: 181px;
}
}
.account-from .account__wrapper .account__header h2 {
  font-size: 35px;
  line-height: 47px;
}

  .account-from .account__wrapper {
    .account__header {
      margin-block-end: 9px;
    }

    .account__divider {
      margin-block: 10px;
    }

    .account__form {
      margin-block: 10px;

      .form-inpu_box {
        margin-block-end: 14px;

        .form-control {
          padding: 9px 15px;
        }

        &.form-pass .form-pass__toggle {
          top: 47px;
        }
      }
    }
  }
}

@media screen and (max-width: 322px) {
  .account-from .account__wrapper .account__wrapper{
    padding: 21px 12px;
  }
}
input.opt_font_card[type="number"]::-webkit-inner-spin-button,
input.opt_font_card[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.slick-slider .slick-slide {
  padding: 0 5px;
}

.react-tel-input .country-list {
  overflow: hidden auto;
  box-shadow: 1px 2px 10px #00000038;
  background-color: #f1eeeed3;
  backdrop-filter: blur(30px);
  transform: matrix(1, 0, 0, 1, 0, 0);

  .search {
    padding: 2px 8px 4px;
    width: 100%;
    position: relative;
    .search-emoji {
      position: absolute;
      top: 11px;
      left: 14px;
      font-size: 14px;
    }
    .search-box {
      margin: 0;
      padding: 4px 9px 5px 31px;
      outline: none;
      width: 100%;
    }
  }
}
