.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: grid;
    place-items: center;
    background-color: #0f0f1a;
    z-index: 99999;
    overflow: hidden;
    transition: opacity 0.77s ease 0s;
  
    .preloader_box {
      position:absolute;
  display: inline-block;
  
      &:before,
     &:after {
    content: '';
    position: absolute;
    left: 50%;
    display: block;
    width: 80px;
    height: 85px;
    border-radius: 0.25em;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  
  
  }
  &:before {
    background-image: url(../assets/images/loader/icon_2.svg);
    animation: before 0s infinite alternate;
    animation-duration: 1s;
    top: -10px;
  
  
  }
  
  &:after {
    background-image: url(../assets/images/loader/icon_1.svg);
    animation: after 0s infinite alternate;
    animation-duration: 1s;
    top: 10px;
  
  }
    }
  
  }
  
  @keyframes before {
    to {
  
      transform: rotate(360deg);
      top: -10px;
    }
  
    from {
      transform: rotate(50deg);
      top: -25px;
    }
  
  }
  
  @keyframes after {
    to {
      transform: rotate(360deg);
      top: 10.8px;
  
    }
  
    from {
      transform: rotate(50deg);
      top: 25px;
  
  
    }
  }