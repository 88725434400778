@import url("https://fonts.googleapis.com/css2?family=Anek+Telugu:wght@100;200;300;400;500;600;700;800&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");


:root {
  --font-name: "Anek Telugu", sans-serif;
  --font-name-a: "Open Sans", sans-serif;
  --man_body_color: #f5f5f5;
  --main_bg_color: linear-gradient(125deg, #2b71dd 0%, #6545f9 100%);
  --main_bg_hover-color: linear-gradient(125deg, #6545f9 0%, #2b71dd 100%);
  --main_bod_color: #6545f9;
  --main_font_color: #6545f9;
  --main-a_bg_color: #6545f9;
  --main-a_bod_color: #3863e0;
  --font-black-color: #323030;
  --font-white-color: #ffff;
}



* {
  margin: 0;
  padding: 0;
  font-family: var(--font-name), Helvetica, serif;
  box-sizing: border-box;
}

body {
  background-color: var(--man_body_color);
  font-family: var(--font-name), Helvetica, serif;
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
  color: var(--font-black-color);
  overflow: hidden auto;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  color: var(--text-color);
  text-decoration: none;
  transition: all 0.3s ease-out;
}

a:focus,
input:focus,
textarea:focus,
button:focus {
  text-decoration: none;
  outline: none;
}

a:focus,
a:hover {
  text-decoration: none;
}

i,
span,
a {
  display: inline-block;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-name), Helvetica, serif;
  font-weight: 700;
  color: var(--font-black-color);
  margin: 0;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: #0c263a;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #f8fafc;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 22px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-family: var(--font-name), Helvetica, serif;
}

p {
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  font-family: var(--font-name), Helvetica, serif;
  color: var(--font-black-color);
  margin: 0;
}

select:focus-visible {
  outline: none;
}


::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: transparent;
    -webkit-border-radius: 100px;
    border-radius: 100px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  
  }
  
  
  ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    -webkit-border-radius: 100px;
    border-radius: 100px;
    background-clip: padding-box;
    border: 1px solid transparent;
    min-height: 10px;
    transition: all 0.3s linear;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #929292;
  }

  .main_section{
    width: calc(100% - 256px);
    float: right;
    padding: 15px 12px 35px;
    margin: 0;
    display: flex;
    flex-direction: column

  }
  @media screen and (max-width: 992px){
.main_section {
    width: 100%;
    margin: 0 auto;
}
}