.toast-main {
    position: absolute;
    bottom: 30px;
    right: 10px;
    border-radius: 7px;
    padding: 5px 10px;
    box-shadow: 0 6px 20px -5px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transform: translateX(calc(100% + 30px));
    // border-left: 4px solid #790000;
    transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.35);
    z-index: 99999;
    position: fixed;
    &.active {
        transform: translateX(0%);
    }

    .close {

        padding: 5px;
        cursor: pointer;
        opacity: 0.7;
        color: #ffffff;

        &:hover {
            opacity: 1;
        }
    }

    &.error {
        background: #DB4437;
    }

    &.success {
        background-color: #2e7d32;
    }
    .toast-content {
        display: flex;
        align-items: center;
        gap: 6px;
        padding: 5px 2px;
        position: relative;

       

        .message {
            display: block;
            font-weight: 600;
            color: #ffffff;
            text-transform: capitalize;
            font-size: 13px;
            line-height: 21px;
            width: 100%;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden
        }
    }
}
@media screen and (max-width: 592px) {
    .message {
        width: 260px;
   
    }  
}