@mixin animshan_but {
  position: relative;
  overflow: hidden;

  &::after {
    background: #ffffff;
    content: "";
    position: absolute;
    height: 155px;
    left: -75px;
    opacity: 0.2;
    top: -50px;
    transform: rotate(35deg);
    transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    width: 50px;
    z-index: -10;
  }

  &:hover {
    color: var(--font-white-color);
    transform: translateY(-2px);

    &::after {
      left: 180%;
      transition: all 850ms cubic-bezier(0.19, 1, 0.22, 1);
    }
  }
}

@mixin button_box {
  font-size: 1rem;
  font-family: var(--font-name-a);
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 5px;
  background: var(--main_bg_color);
  color: #fff;
  cursor: pointer;
  transition: all 0.4s ease-out 0s;
}

.header-section {
  width: calc(100% - 256px);
  float: right;
  background-color: #ffffff;
  position: relative;
  height: 3.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .header-wrapper {
    width: 100%;
    position: relative;
    padding: 0 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header-bar_icon{
      display: none;
      }

    .menu-area .header-btn {
      display: flex;
      align-items: center;
      gap: 13px;

      .trk-btn--primary {
        padding: 6px 22px;
        @include button_box;
        position: relative;

        .login_buttone {
          width: 26px;
          height: 26px;
          display: none;
        }

        &.trk-btn {
          @include animshan_but;
        }
      }
    }

    .profile-container {
      position: relative;
      display: inline-block;
      float: right;

   
      .profile-button {
        display: flex;
        align-items: center;
        cursor: pointer;
        gap: 7px;

        img {
          width: 33px;
          height: 33px;
          border-radius: 50%;
        }
        .user_name {
          white-space: nowrap;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 17px;
          text-transform: capitalize;
          text-decoration: none;
          color: #57546c;
        }
      }

      .dropdown-menu {
        position: absolute;
        top: 45px;
        right: 0;
        display: block;
        background: #ffffffa8;
        border: 1px solid #464e7446;
        box-shadow: 0 10px 13px -6px #80808033, 0 20px 31px 3px #c7c7c724, 0 8px 38px 7px #c2c2c21f;
        backdrop-filter: blur(30px);
        transform: matrix(1, 0, 0, 1, 0, 0);
        border-radius: 5px;
        padding: 8px 0;
        z-index: 1;

        .user_link {
          display: flex;
          font-size: 17px;
          line-height: 17px;
          text-decoration: none;
          color: #333;
          align-items: center;
          grid-gap: 10px;
          gap: 10px;
          border-left: 2.8px solid transparent;
          padding: 8px 12px;
    

          &:hover {
            color: #4719dd;
            border-left:2.8px solid #4719dd ;
  
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .header-section {
    margin: 0;
    width: 100%;
    .header-wrapper   .header-bar_icon{
      display: block;
      }
      .header-section .header-wrapper .header-bar_icon{
        display: block;
      }
  }



 

} // Only Header css write here...
