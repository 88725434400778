.dashboard-box-body{
    display: flex;
    gap: 10px;
}
.heding_box{
    background-color: #ffffff;
    border-left: 3px solid #4719dd;
    color: #4719dd;
    font-size: 18px;
    font-weight: 500;
    line-height: 0;
    text-transform: capitalize;
    width: 100%;
    height: 2.56rem ;
    padding: 5px 0px 0px 12px;
    margin: 0 0 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.mat-mdc-card{
    box-shadow: 0 4px 20px #2f8fe812;
    background-color: #fff !important;
    border-radius: 10px !important;
    display: block !important;
    border: none !important;
    padding: 20px;
   position: relative;
   .icontext{
    display: flex;
    align-items: center;
    justify-content: space-between;
   span.icon {
    background-color: #f5f3ff;
    color: #573097;
    font-size: 23px;
    padding: 11px 16px;
    border-radius: 10px !important;
   }
   .text{
    text-align: end;
    h6{
        font-size: 17px;
        font-weight: 600;
        text-transform: capitalize;
        color: #573097;
    }
    span{
        font-size: 20px;
        font-weight: 600;
        text-transform: capitalize;
        color: #000;
    }
   }
}
}